<template>
  <v-footer id="dashboard-core-footer"
    style="margin: 0px !important;color:#ffffff;background-color:#0a14b4;height:100px">
    <v-container>
      <v-row align="center" no-gutters>

        <v-spacer class="hidden-sm-and-down" />

        <v-col cols="12" md="auto">
          <div>
            <span style="padding:15px">FNTP 2024</span>
            <a style="color:#FFFFFF;font-size:1rem !important"
              href="https://acteurspourlaplanete.fntp.fr/mentions-legales/">
              <span>Mentions légales</span></a>
            <a style="color:#FFFFFF;font-size:1rem !important"
              href="https://my.plantnet.org/terms_of_use#access_policy">
              <span>Conditions d'utilisation PlantNet</span></a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      dialog_legal_mentions: false,
      links: [
        {
          href: '#',
          text: 'Mentions légales',
        },
      ],
    }),
    methods: {
     gotoMentionLegales()
     {
      window.location.href='https://acteurspourlaplanete.fntp.fr/mentions-legales/';
     },
    },
  }
</script>
